// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ecommerce API
 * ecommerce API documentation
 *
 * The version of the OpenAPI document: 0.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SkuList,
    SkuListFromJSON,
    SkuListToJSON,
} from '../models';

export interface UpdateHotProductsUsingPOSTRequest {
    skuList: Array<SkuList>;
}

/**
 * 
 */
export class SkuListApi extends runtime.BaseAPI {

    /**
     * Get all catalogs with attributes
     */
    async getHotProductsUsingGETRaw(): Promise<runtime.ApiResponse<Array<SkuList>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/hotproducts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SkuListFromJSON));
    }

    /**
     * Get all catalogs with attributes
     */
    async getHotProductsUsingGET(): Promise<Array<SkuList>> {
        const response = await this.getHotProductsUsingGETRaw();
        return await response.value();
    }

    /**
     * Get all catalogs with attributes
     */
    async updateHotProductsUsingPOSTRaw(requestParameters: UpdateHotProductsUsingPOSTRequest): Promise<runtime.ApiResponse<Array<SkuList>>> {
        if (requestParameters.skuList === null || requestParameters.skuList === undefined) {
            throw new runtime.RequiredError('skuList','Required parameter requestParameters.skuList was null or undefined when calling updateHotProductsUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/hotproducts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.skuList.map(SkuListToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SkuListFromJSON));
    }

    /**
     * Get all catalogs with attributes
     */
    async updateHotProductsUsingPOST(requestParameters: UpdateHotProductsUsingPOSTRequest): Promise<Array<SkuList>> {
        const response = await this.updateHotProductsUsingPOSTRaw(requestParameters);
        return await response.value();
    }

}
