import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import BannerVideoBackground from './BannerVideoBackground'
import Button from './Button'
import ReactModal from './ReactModal'

const GlobalStyle = createGlobalStyle`
    .ReactModal__Overlay {
        overflow: auto;
    }

  div[aria-label='RecoverModal'] {
    overflow: initial !important;
    margin: 60px 0 30px 0;
    position: relative !important;
    width: min(calc(100vw - 2*20px), 285px);

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: min(calc(100vw - 2*20px), 933px);
      margin: 0;
    }
  }
`

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(229, 229, 229, 0.5)',
  },
  content: {
    border: 'none',
    background: '#FFFFFF',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    padding: 0,
  },
}

const Container = styled.div`
  position: relative;
  padding: 15px 20px;
  display: grid;
  gap: 13px;
  grid-template-areas:
    'header'
    'video'
    'button'
    'content';

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 30px 20px;
    column-gap: 30px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'video header'
      'video content'
      'video button';
  }

  & > *[class^='RecoverModal__CloseButton'] {
    position: absolute;
    top: -12px;
    right: -12px;
  }

  & > *[class^='RecoverModal__Header'] {
    grid-area: header;
  }

  & > *[class*='BannerVideoBackground'] {
    grid-area: video;
    margin: auto;
  }

  & > *[class^='RecoverModal__Content'] {
    grid-area: content;
  }

  & > *[class^='Button'] {
    grid-area: button;
    margin: auto;
  }
`

const CloseButton = styled.span`
  cursor: pointer;
  display: inline-flex;
`

const Header = styled.div`
  & > :last-child {
    margin-top: -8px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 0;
    }
  }
`

const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 42px;
  color: ${({ theme }) => theme.colors.secondary};
`

const SubTitle = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.secondary};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 18px;
    line-height: 22px;
  }
`

const Content = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.secondary};

  p {
    margin-bottom: 10px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-bottom: 14px;
    }
  }

  ul {
    list-style: disc;
    padding-left: 20px;
  }
`

interface RecoverModalProps {
  isOpen: boolean
  onRequestClose: () => void
  onAccept: () => void
}

const RecoverModal: React.FC<RecoverModalProps> = ({
  isOpen,
  onRequestClose,
  onAccept,
}) => (
  <>
    <GlobalStyle />
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="RecoverModal"
    >
      <Container>
        <CloseButton onClick={onRequestClose}>
          <StaticImage
            src="../images/RecoverModalClose.svg"
            alt=""
            placeholder="blurred"
            quality={100}
          />
        </CloseButton>

        <Header>
          <Title>reCover</Title>
          <SubTitle>Protection sur-mesure &amp; anti-choc</SubTitle>
        </Header>

        <BannerVideoBackground
          width={690}
          height={321}
          ratio={852 / 480}
          cropFactor={1}
          src="/videos/Recover_Save.mp4"
          type="video/mp4"
          poster="/videos/Recover_Save_Moment.jpg"
        />
        <Content>
          <p>
            La protection 360° reCover : la meilleure solution pour protéger
            pour votre smartphone !
          </p>

          <p>
            Votre smartphone est un bijou de technologie. Puissants, esthétiques
            et onéreux, nos téléphones sont aussi fragiles. L’écran comme
            l’arrière de nos smartphones sont sensibles aux chocs et aux
            rayures. Pour protéger votre smartphone, il vous faut une protection
            résistante, durable et fiable.
          </p>

          <p>Qu'est ce que c'est reCover ?</p>

          <ul>
            <li>Un film ultra fin</li>
            <li>Résistante aux rayures et aux chocs</li>
            <li>Invisible, vous conservez le design de votre smartphone</li>
            <li>
              Protège l'écran, l'arrière et même les bords de votre smartphone
            </li>
            <li>
              Auto-régénérant : Dans un temps record, votre écran est à nouveau
              protégé
            </li>
            <li>
              Garantie à vie, nous la changeons ou la repositionnons
              gratuitement
            </li>
            <li>
              reCover, le service haut de gamme pour protéger votre téléphone
              avec classe !
            </li>
          </ul>
        </Content>

        <Button onClick={onAccept}>Ajouter à ma réservation</Button>
      </Container>
    </ReactModal>
  </>
)

export default RecoverModal
