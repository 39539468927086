import styled from 'styled-components'

const RepairOrderContainer = styled.div`
  flex: 1;
  padding: 0 12px 23px 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 24px;
  }

  & > * {
    width: 100%;
  }

  & > *[class^='RepairOrderCard'] {
    max-width: 848px;
    margin: 0 auto;
  }

  & > *[class^='RepairOrderRecover'] {
    max-width: 848px;
    margin: 0 auto;
  }
`

export default RepairOrderContainer
