// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ecommerce API
 * ecommerce API documentation
 *
 * The version of the OpenAPI document: 0.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Cart,
    CartFromJSON,
    CartToJSON,
} from '../models';

export interface CreateCartUsingPOSTRequest {
    cart: Cart;
}

export interface DeleteCartUsingDELETERequest {
    id: number;
}

export interface GetCartUsingGETRequest {
    id: number;
}

export interface UpdateCartUsingPUTRequest {
    cart: Cart;
}

/**
 * 
 */
export class CartResourceApi extends runtime.BaseAPI {

    /**
     * createCart
     */
    async createCartUsingPOSTRaw(requestParameters: CreateCartUsingPOSTRequest): Promise<runtime.ApiResponse<Cart>> {
        if (requestParameters.cart === null || requestParameters.cart === undefined) {
            throw new runtime.RequiredError('cart','Required parameter requestParameters.cart was null or undefined when calling createCartUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/carts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CartToJSON(requestParameters.cart),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CartFromJSON(jsonValue));
    }

    /**
     * createCart
     */
    async createCartUsingPOST(requestParameters: CreateCartUsingPOSTRequest): Promise<Cart> {
        const response = await this.createCartUsingPOSTRaw(requestParameters);
        return await response.value();
    }

    /**
     * deleteCart
     */
    async deleteCartUsingDELETERaw(requestParameters: DeleteCartUsingDELETERequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCartUsingDELETE.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/carts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deleteCart
     */
    async deleteCartUsingDELETE(requestParameters: DeleteCartUsingDELETERequest): Promise<void> {
        await this.deleteCartUsingDELETERaw(requestParameters);
    }

    /**
     * getAllCarts
     */
    async getAllCartsUsingGETRaw(): Promise<runtime.ApiResponse<Array<Cart>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/carts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CartFromJSON));
    }

    /**
     * getAllCarts
     */
    async getAllCartsUsingGET(): Promise<Array<Cart>> {
        const response = await this.getAllCartsUsingGETRaw();
        return await response.value();
    }

    /**
     * getCart
     */
    async getCartUsingGETRaw(requestParameters: GetCartUsingGETRequest): Promise<runtime.ApiResponse<Cart>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCartUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/carts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CartFromJSON(jsonValue));
    }

    /**
     * getCart
     */
    async getCartUsingGET(requestParameters: GetCartUsingGETRequest): Promise<Cart> {
        const response = await this.getCartUsingGETRaw(requestParameters);
        return await response.value();
    }

    /**
     * updateCart
     */
    async updateCartUsingPUTRaw(requestParameters: UpdateCartUsingPUTRequest): Promise<runtime.ApiResponse<Cart>> {
        if (requestParameters.cart === null || requestParameters.cart === undefined) {
            throw new runtime.RequiredError('cart','Required parameter requestParameters.cart was null or undefined when calling updateCartUsingPUT.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/carts`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CartToJSON(requestParameters.cart),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CartFromJSON(jsonValue));
    }

    /**
     * updateCart
     */
    async updateCartUsingPUT(requestParameters: UpdateCartUsingPUTRequest): Promise<Cart> {
        const response = await this.updateCartUsingPUTRaw(requestParameters);
        return await response.value();
    }

}
