import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { reparationsLinks } from '../data/menu.context'
import Button from './Button'

const Container = styled.div`
  padding: 11px 15px 17px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 57px 57px 57px 57px;
  }

  & > *[class^='RepairError__Message'] {
    max-width: 260px;
    margin-bottom: 17px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      max-width: 310px;
      margin-bottom: 30px;
    }
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 14px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
  }

  & > * {
    width: 133px;
    white-space: nowrap;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 180px;
    }
  }
`

const Image = styled.span`
  display: inline-block;
  max-width: 193px;
  max-height: 193px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 365px;
    max-height: 365px;
  }

  & > div {
    width: 100%;
    height: 100%;
  }
`

const Message = styled.p`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: ${({ theme }) => theme.colors.body};
`

interface RepairErrorProps {}

const RepairError: React.FC<RepairErrorProps> = () => {
  const pathname = window.location.pathname.slice(0, -1)

  const shopPath = pathname.substring(0, pathname.lastIndexOf('/')) + '/'

  return (
    <Container>
      <Image>
        <StaticImage
          id="image-oups"
          src="../images/Oups.png"
          alt="Oups"
          width={365}
          height={365}
          quality={100}
          placeholder="blurred"
        />
      </Image>
      <Message>
        Ce magasin n’est pas en mesure d’éditer un bon de réparation. Vous
        pouvez sélectionner un autre magasin ou effectuer un devis.
      </Message>
      <Buttons>
        <Button.Link to={reparationsLinks.other}>Créer un devis</Button.Link>
        <Button.Link to={shopPath} color="muted">
          Retour
        </Button.Link>
      </Buttons>
    </Container>
  )
}

export default RepairError
