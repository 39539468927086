// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ecommerce API
 * ecommerce API documentation
 *
 * The version of the OpenAPI document: 0.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Orders,
    OrdersFromJSON,
    OrdersToJSON,
} from '../models';

export interface CreateOrdersUsingPOSTRequest {
    orders: Orders;
}

export interface DeleteOrdersUsingDELETERequest {
    id: number;
}

export interface GetOrdersByPAiementIdUsingGETRequest {
    paiementId: number;
}

export interface GetOrdersUsingGETRequest {
    id: number;
}

export interface UpdateOrdersUsingPUTRequest {
    orders: Orders;
}

/**
 * 
 */
export class OrdersResourceApi extends runtime.BaseAPI {

    /**
     * createOrders
     */
    async createOrdersUsingPOSTRaw(requestParameters: CreateOrdersUsingPOSTRequest): Promise<runtime.ApiResponse<Orders>> {
        if (requestParameters.orders === null || requestParameters.orders === undefined) {
            throw new runtime.RequiredError('orders','Required parameter requestParameters.orders was null or undefined when calling createOrdersUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/orders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrdersToJSON(requestParameters.orders),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrdersFromJSON(jsonValue));
    }

    /**
     * createOrders
     */
    async createOrdersUsingPOST(requestParameters: CreateOrdersUsingPOSTRequest): Promise<Orders> {
        const response = await this.createOrdersUsingPOSTRaw(requestParameters);
        return await response.value();
    }

    /**
     * deleteOrders
     */
    async deleteOrdersUsingDELETERaw(requestParameters: DeleteOrdersUsingDELETERequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOrdersUsingDELETE.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deleteOrders
     */
    async deleteOrdersUsingDELETE(requestParameters: DeleteOrdersUsingDELETERequest): Promise<void> {
        await this.deleteOrdersUsingDELETERaw(requestParameters);
    }

    /**
     * getAllOrders
     */
    async getAllOrdersUsingGETRaw(): Promise<runtime.ApiResponse<Array<Orders>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/orders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrdersFromJSON));
    }

    /**
     * getAllOrders
     */
    async getAllOrdersUsingGET(): Promise<Array<Orders>> {
        const response = await this.getAllOrdersUsingGETRaw();
        return await response.value();
    }

    /**
     * getOrdersByPAiementId
     */
    async getOrdersByPAiementIdUsingGETRaw(requestParameters: GetOrdersByPAiementIdUsingGETRequest): Promise<runtime.ApiResponse<Orders>> {
        if (requestParameters.paiementId === null || requestParameters.paiementId === undefined) {
            throw new runtime.RequiredError('paiementId','Required parameter requestParameters.paiementId was null or undefined when calling getOrdersByPAiementIdUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ordersByPaiementId/{paiementId}`.replace(`{${"paiementId"}}`, encodeURIComponent(String(requestParameters.paiementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrdersFromJSON(jsonValue));
    }

    /**
     * getOrdersByPAiementId
     */
    async getOrdersByPAiementIdUsingGET(requestParameters: GetOrdersByPAiementIdUsingGETRequest): Promise<Orders> {
        const response = await this.getOrdersByPAiementIdUsingGETRaw(requestParameters);
        return await response.value();
    }

    /**
     * getOrders
     */
    async getOrdersUsingGETRaw(requestParameters: GetOrdersUsingGETRequest): Promise<runtime.ApiResponse<Orders>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOrdersUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrdersFromJSON(jsonValue));
    }

    /**
     * getOrders
     */
    async getOrdersUsingGET(requestParameters: GetOrdersUsingGETRequest): Promise<Orders> {
        const response = await this.getOrdersUsingGETRaw(requestParameters);
        return await response.value();
    }

    /**
     * updateOrders
     */
    async updateOrdersUsingPUTRaw(requestParameters: UpdateOrdersUsingPUTRequest): Promise<runtime.ApiResponse<Orders>> {
        if (requestParameters.orders === null || requestParameters.orders === undefined) {
            throw new runtime.RequiredError('orders','Required parameter requestParameters.orders was null or undefined when calling updateOrdersUsingPUT.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/orders`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrdersToJSON(requestParameters.orders),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrdersFromJSON(jsonValue));
    }

    /**
     * updateOrders
     */
    async updateOrdersUsingPUT(requestParameters: UpdateOrdersUsingPUTRequest): Promise<Orders> {
        const response = await this.updateOrdersUsingPUTRaw(requestParameters);
        return await response.value();
    }

}
