// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ecommerce API
 * ecommerce API documentation
 *
 * The version of the OpenAPI document: 0.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SkuList,
    SkuListFromJSON,
    SkuListToJSON,
} from '../models';

export interface CreateSkuListUsingPOSTRequest {
    skuList: SkuList;
}

export interface DeleteSkuListUsingDELETERequest {
    id: number;
}

export interface GetSkuListUsingGETRequest {
    id: number;
}

export interface UpdateSkuListUsingPUTRequest {
    skuList: SkuList;
}

/**
 * 
 */
export class SkuListResourceApi extends runtime.BaseAPI {

    /**
     * createSkuList
     */
    async createSkuListUsingPOSTRaw(requestParameters: CreateSkuListUsingPOSTRequest): Promise<runtime.ApiResponse<SkuList>> {
        if (requestParameters.skuList === null || requestParameters.skuList === undefined) {
            throw new runtime.RequiredError('skuList','Required parameter requestParameters.skuList was null or undefined when calling createSkuListUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/sku-lists`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SkuListToJSON(requestParameters.skuList),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SkuListFromJSON(jsonValue));
    }

    /**
     * createSkuList
     */
    async createSkuListUsingPOST(requestParameters: CreateSkuListUsingPOSTRequest): Promise<SkuList> {
        const response = await this.createSkuListUsingPOSTRaw(requestParameters);
        return await response.value();
    }

    /**
     * deleteSkuList
     */
    async deleteSkuListUsingDELETERaw(requestParameters: DeleteSkuListUsingDELETERequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSkuListUsingDELETE.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/sku-lists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deleteSkuList
     */
    async deleteSkuListUsingDELETE(requestParameters: DeleteSkuListUsingDELETERequest): Promise<void> {
        await this.deleteSkuListUsingDELETERaw(requestParameters);
    }

    /**
     * getAllSkuLists
     */
    async getAllSkuListsUsingGETRaw(): Promise<runtime.ApiResponse<Array<SkuList>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/sku-lists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SkuListFromJSON));
    }

    /**
     * getAllSkuLists
     */
    async getAllSkuListsUsingGET(): Promise<Array<SkuList>> {
        const response = await this.getAllSkuListsUsingGETRaw();
        return await response.value();
    }

    /**
     * getSkuList
     */
    async getSkuListUsingGETRaw(requestParameters: GetSkuListUsingGETRequest): Promise<runtime.ApiResponse<SkuList>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSkuListUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/sku-lists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SkuListFromJSON(jsonValue));
    }

    /**
     * getSkuList
     */
    async getSkuListUsingGET(requestParameters: GetSkuListUsingGETRequest): Promise<SkuList> {
        const response = await this.getSkuListUsingGETRaw(requestParameters);
        return await response.value();
    }

    /**
     * updateSkuList
     */
    async updateSkuListUsingPUTRaw(requestParameters: UpdateSkuListUsingPUTRequest): Promise<runtime.ApiResponse<SkuList>> {
        if (requestParameters.skuList === null || requestParameters.skuList === undefined) {
            throw new runtime.RequiredError('skuList','Required parameter requestParameters.skuList was null or undefined when calling updateSkuListUsingPUT.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/sku-lists`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SkuListToJSON(requestParameters.skuList),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SkuListFromJSON(jsonValue));
    }

    /**
     * updateSkuList
     */
    async updateSkuListUsingPUT(requestParameters: UpdateSkuListUsingPUTRequest): Promise<SkuList> {
        const response = await this.updateSkuListUsingPUTRaw(requestParameters);
        return await response.value();
    }

}
