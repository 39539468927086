import React, { /** useCallback, */ useMemo } from 'react'
import { repairSummaryPageStepLabel } from '../templates/RepairSummaryPage.context'
import Button from './Button'
import { useLayoutContext /** , useLocation */ } from './Layout'
import { LayoutProps } from './Layout.context'
import SmartphoneSendCard from './SmartphoneSendCard'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import SmartphoneSendContainer from './SmartphoneSendContainer'
import { QuoteResult } from '../models/QuoteResult'
import formatPrice from '../utils/formatPrice'
import { StaticImage } from 'gatsby-plugin-image'
// import {
//   buildSummaryUrlWithParams,
//   PayboxResultValue,
// } from '../templates/RepairSummaryPage'

interface RepairSummaryPaymentProps {
  layoutProps: LayoutProps
  onBack: () => void
  quote: QuoteResult
  email: string
}

const RepairSummaryPayment: React.FC<RepairSummaryPaymentProps> = ({
  layoutProps,
  onBack,
  quote,
  // email,
}) => {
  // const location = useLocation()

  // const payboxResultUrl = useCallback(
  //   (value: PayboxResultValue) => {
  //     if (!location) {
  //       return undefined
  //     }
  //     return buildSummaryUrlWithParams(location, {
  //       paybox_result: value,
  //       email,
  //       intervention_id: quote.intervention_id.toString(),
  //       billing_id: quote.billDetails[0].billingId.toString(),
  //     })
  //   },
  //   [location, email, quote]
  // )

  const layoutPropsComputed: LayoutProps = useMemo(() => {
    const props: LayoutProps = {
      repairStepsContainerProps: {
        showHero: false,
      },
      repairStepsProps: {
        ...layoutProps.repairStepsProps,
        step3: [{ label: repairSummaryPageStepLabel, url: onBack }],
      },
    }

    return merge(cloneDeep(layoutProps), props)
  }, [layoutProps])

  useLayoutContext(layoutPropsComputed)

  const payment = useMemo(() => {
    return quote.onlinePaiements.length > 0
      ? quote.onlinePaiements[0]
      : undefined
  }, [quote])

  if (payment === undefined) {
    return <div>Erreur: Aucun paiement</div>
  }

  return (
    <SmartphoneSendContainer>
      <SmartphoneSendCard>
        <SmartphoneSendCard.Title>Pré-paiement</SmartphoneSendCard.Title>
        <SmartphoneSendCard.SubTitle>
          Afin de procéder à l’envoi de votre téléphone
        </SmartphoneSendCard.SubTitle>
        <SmartphoneSendCard.Description>
          Afin d’obtenir votre bon de transport pour envoyer votre téléphone en
          magasin, nous vous demandons une participation initiale de{' '}
          {formatPrice(payment.amountTTC)}.
          <br />
          Nous vous remercions de votre compréhension
        </SmartphoneSendCard.Description>

        <StaticImage
          src="../images/paybox.png"
          alt="paybox"
          placeholder="blurred"
          quality={100}
          width={200}
          height={96}
        />

        <form method="POST" action={process.env.GATSBY_PAYBOX_URL}>
          <input type="hidden" name="PBX_SITE" value={payment.pbx_SITE} />
          <input type="hidden" name="PBX_RANG" value={payment.pbx_RANG} />
          <input
            type="hidden"
            name="PBX_IDENTIFIANT"
            value={payment.pbx_IDENTIFIANT}
          />
          <input type="hidden" name="PBX_TOTAL" value={payment.pbx_TOTAL} />
          <input type="hidden" name="PBX_DEVISE" value={payment.pbx_DEVISE} />
          <input type="hidden" name="PBX_CMD" value={payment.pbx_CMD} />
          <input type="hidden" name="PBX_PORTEUR" value={payment.pbx_PORTEUR} />
          <input type="hidden" name="PBX_RETOUR" value={payment.pbx_RETOUR} />
          <input type="hidden" name="PBX_HASH" value="SHA512" />
          <input type="hidden" name="PBX_TIME" value={payment.pbx_TIME} />
          <input type="hidden" name="PBX_HMAC" value={payment.pbx_HASH} />
          <input
            type="hidden"
            name="PBX_ANNULE"
            value="https://preprod.save.co/validation?errorCode=001"
            // value={payboxResultUrl('canceled')}
          />
          <input
            type="hidden"
            name="PBX_EFFECTUE"
            value="https://preprod.save.co/validation?paiementSuccess=000"
            // value={payboxResultUrl('success')}
          />
          <input
            type="hidden"
            name="PBX_REPONDRE_A"
            value={process.env.GATSBY_PAYBOX_RESPONSE_URL}
          />
          {/* <input
            type="hidden"
            name="PBX_REFUSE"
            value={payboxResultUrl('refused')}
          />
          <input
            type="hidden"
            name="PBX_ATTENTE"
            value={payboxResultUrl('waiting')}
          /> */}
          <Button type="submit">Procéder au paiement du transport</Button>
        </form>
      </SmartphoneSendCard>
    </SmartphoneSendContainer>
  )
}

export default RepairSummaryPayment
