import styled from 'styled-components'

const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};
`

const Description = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #000000;
  max-width: 590px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 14px;
    line-height: 16px;
  }
`

const Buttons = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

const Button = styled.button``

const RepairOrderCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 49px 16px 28px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;
  z-index: 0;
  position: relative;

  & > *[class^='RepairOrderCard__Description'] {
    margin-top: -15px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    gap: 26px;
  }
`

export default Object.assign(RepairOrderCard, {
  Title,
  Description,
  Buttons,
  Button,
})
