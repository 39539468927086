import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import formatPrice from '../utils/formatPrice'

const Title = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
`

const Description = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
`

interface PriceProps {
  value: number
}

const PriceContainer = styled.div`
  background: #ffffff;
  border-radius: 39px;
  padding: 6px 12px 2px 12px;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: ${({ theme }) => theme.colors.tertiary};
`

const Price: React.FC<PriceProps> = ({ value }) => (
  <PriceContainer>Face avant : {formatPrice(value)}</PriceContainer>
)

const RepairOrderRecoverContainer = styled(Link)`
  text-decoration: none;
  background: ${({ theme }) => theme.colors.tertiary};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  & > div:first-child {
    flex: 1;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      display: none;
    }
  }

  & > div:last-child {
    max-width: 397px;
    flex: 40;
  }
`

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 30px;

  & > div[data-gatsby-image-wrapper] {
    position: absolute;
    top: 11px;
    right: 11px;
  }

  & > *[class^='RepairOrderRecover__PriceContainer']  {
    align-self: flex-end;
  }
`

interface RepairOrderRecoverProps {
  to: string
  children: React.ReactNode
}

const RepairOrderRecover: React.FC<RepairOrderRecoverProps> = ({
  to,
  children,
}) => (
  <RepairOrderRecoverContainer to={to}>
    <StaticImage
      src="../images/RepairOrderRecover.jpg"
      alt=""
      placeholder="blurred"
      quality={100}
    />
    <Content>
      <StaticImage
        src="../images/Indicator.svg"
        alt=""
        placeholder="blurred"
        quality={100}
      />
      {children}
    </Content>
  </RepairOrderRecoverContainer>
)

export default Object.assign(RepairOrderRecover, {
  Title,
  Description,
  Price,
})
