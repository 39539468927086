// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ecommerce API
 * ecommerce API documentation
 *
 * The version of the OpenAPI document: 0.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Customer,
    CustomerFromJSON,
    CustomerToJSON,
} from '../models';

export interface CreateCustomerUsingPOSTRequest {
    customer: Customer;
}

export interface DeleteCustomerUsingDELETERequest {
    id: number;
}

export interface GetCustomerUsingGETRequest {
    id: number;
}

export interface UpdateCustomerUsingPUTRequest {
    customer: Customer;
}

/**
 * 
 */
export class CustomerResourceApi extends runtime.BaseAPI {

    /**
     * createCustomer
     */
    async createCustomerUsingPOSTRaw(requestParameters: CreateCustomerUsingPOSTRequest): Promise<runtime.ApiResponse<Customer>> {
        if (requestParameters.customer === null || requestParameters.customer === undefined) {
            throw new runtime.RequiredError('customer','Required parameter requestParameters.customer was null or undefined when calling createCustomerUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/customers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerToJSON(requestParameters.customer),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerFromJSON(jsonValue));
    }

    /**
     * createCustomer
     */
    async createCustomerUsingPOST(requestParameters: CreateCustomerUsingPOSTRequest): Promise<Customer> {
        const response = await this.createCustomerUsingPOSTRaw(requestParameters);
        return await response.value();
    }

    /**
     * deleteCustomer
     */
    async deleteCustomerUsingDELETERaw(requestParameters: DeleteCustomerUsingDELETERequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCustomerUsingDELETE.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/customers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deleteCustomer
     */
    async deleteCustomerUsingDELETE(requestParameters: DeleteCustomerUsingDELETERequest): Promise<void> {
        await this.deleteCustomerUsingDELETERaw(requestParameters);
    }

    /**
     * getAllCustomers
     */
    async getAllCustomersUsingGETRaw(): Promise<runtime.ApiResponse<Array<Customer>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/customers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerFromJSON));
    }

    /**
     * getAllCustomers
     */
    async getAllCustomersUsingGET(): Promise<Array<Customer>> {
        const response = await this.getAllCustomersUsingGETRaw();
        return await response.value();
    }

    /**
     * getCustomer
     */
    async getCustomerUsingGETRaw(requestParameters: GetCustomerUsingGETRequest): Promise<runtime.ApiResponse<Customer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCustomerUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/customers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerFromJSON(jsonValue));
    }

    /**
     * getCustomer
     */
    async getCustomerUsingGET(requestParameters: GetCustomerUsingGETRequest): Promise<Customer> {
        const response = await this.getCustomerUsingGETRaw(requestParameters);
        return await response.value();
    }

    /**
     * updateCustomer
     */
    async updateCustomerUsingPUTRaw(requestParameters: UpdateCustomerUsingPUTRequest): Promise<runtime.ApiResponse<Customer>> {
        if (requestParameters.customer === null || requestParameters.customer === undefined) {
            throw new runtime.RequiredError('customer','Required parameter requestParameters.customer was null or undefined when calling updateCustomerUsingPUT.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/customers`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerToJSON(requestParameters.customer),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerFromJSON(jsonValue));
    }

    /**
     * updateCustomer
     */
    async updateCustomerUsingPUT(requestParameters: UpdateCustomerUsingPUTRequest): Promise<Customer> {
        const response = await this.updateCustomerUsingPUTRaw(requestParameters);
        return await response.value();
    }

}
