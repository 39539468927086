const saveFile = (blob: Blob, filename: string) => {
  const a = document.createElement('a')
  a.style.display = 'none'
  document.body.appendChild(a)
  const url = URL.createObjectURL(blob)
  a.href = url
  a.download = filename
  a.click()
  URL.revokeObjectURL(url)
  document.body.removeChild(a)
}

export default saveFile
