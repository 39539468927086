// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ecommerce API
 * ecommerce API documentation
 *
 * The version of the OpenAPI document: 0.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserAccount,
    UserAccountFromJSON,
    UserAccountToJSON,
} from '../models';

export interface CreateUserAccountUsingPOSTRequest {
    userAccount: UserAccount;
}

export interface DeleteUserAccountUsingDELETERequest {
    id: number;
}

export interface GetUserAccountUsingGETRequest {
    id: number;
}

export interface UpdateUserAccountUsingPUTRequest {
    userAccount: UserAccount;
}

/**
 * 
 */
export class UserAccountResourceApi extends runtime.BaseAPI {

    /**
     * createUserAccount
     */
    async createUserAccountUsingPOSTRaw(requestParameters: CreateUserAccountUsingPOSTRequest): Promise<runtime.ApiResponse<UserAccount>> {
        if (requestParameters.userAccount === null || requestParameters.userAccount === undefined) {
            throw new runtime.RequiredError('userAccount','Required parameter requestParameters.userAccount was null or undefined when calling createUserAccountUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/user-accounts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserAccountToJSON(requestParameters.userAccount),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAccountFromJSON(jsonValue));
    }

    /**
     * createUserAccount
     */
    async createUserAccountUsingPOST(requestParameters: CreateUserAccountUsingPOSTRequest): Promise<UserAccount> {
        const response = await this.createUserAccountUsingPOSTRaw(requestParameters);
        return await response.value();
    }

    /**
     * deleteUserAccount
     */
    async deleteUserAccountUsingDELETERaw(requestParameters: DeleteUserAccountUsingDELETERequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserAccountUsingDELETE.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/user-accounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deleteUserAccount
     */
    async deleteUserAccountUsingDELETE(requestParameters: DeleteUserAccountUsingDELETERequest): Promise<void> {
        await this.deleteUserAccountUsingDELETERaw(requestParameters);
    }

    /**
     * getAllUserAccounts
     */
    async getAllUserAccountsUsingGETRaw(): Promise<runtime.ApiResponse<Array<UserAccount>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/user-accounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserAccountFromJSON));
    }

    /**
     * getAllUserAccounts
     */
    async getAllUserAccountsUsingGET(): Promise<Array<UserAccount>> {
        const response = await this.getAllUserAccountsUsingGETRaw();
        return await response.value();
    }

    /**
     * getUserAccount
     */
    async getUserAccountUsingGETRaw(requestParameters: GetUserAccountUsingGETRequest): Promise<runtime.ApiResponse<UserAccount>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserAccountUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/user-accounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAccountFromJSON(jsonValue));
    }

    /**
     * getUserAccount
     */
    async getUserAccountUsingGET(requestParameters: GetUserAccountUsingGETRequest): Promise<UserAccount> {
        const response = await this.getUserAccountUsingGETRaw(requestParameters);
        return await response.value();
    }

    /**
     * updateUserAccount
     */
    async updateUserAccountUsingPUTRaw(requestParameters: UpdateUserAccountUsingPUTRequest): Promise<runtime.ApiResponse<UserAccount>> {
        if (requestParameters.userAccount === null || requestParameters.userAccount === undefined) {
            throw new runtime.RequiredError('userAccount','Required parameter requestParameters.userAccount was null or undefined when calling updateUserAccountUsingPUT.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/user-accounts`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserAccountToJSON(requestParameters.userAccount),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAccountFromJSON(jsonValue));
    }

    /**
     * updateUserAccount
     */
    async updateUserAccountUsingPUT(requestParameters: UpdateUserAccountUsingPUTRequest): Promise<UserAccount> {
        const response = await this.updateUserAccountUsingPUTRaw(requestParameters);
        return await response.value();
    }

}
