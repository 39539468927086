// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ecommerce API
 * ecommerce API documentation
 *
 * The version of the OpenAPI document: 0.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressToJSON,
} from '../models';

export interface CreateAddressUsingPOSTRequest {
    address: Address;
}

export interface DeleteAddressUsingDELETERequest {
    id: number;
}

export interface GetAddressUsingGETRequest {
    id: number;
}

export interface UpdateAddressUsingPUTRequest {
    address: Address;
}

/**
 * 
 */
export class AddressResourceApi extends runtime.BaseAPI {

    /**
     * createAddress
     */
    async createAddressUsingPOSTRaw(requestParameters: CreateAddressUsingPOSTRequest): Promise<runtime.ApiResponse<Address>> {
        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling createAddressUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/addresses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddressToJSON(requestParameters.address),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressFromJSON(jsonValue));
    }

    /**
     * createAddress
     */
    async createAddressUsingPOST(requestParameters: CreateAddressUsingPOSTRequest): Promise<Address> {
        const response = await this.createAddressUsingPOSTRaw(requestParameters);
        return await response.value();
    }

    /**
     * deleteAddress
     */
    async deleteAddressUsingDELETERaw(requestParameters: DeleteAddressUsingDELETERequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAddressUsingDELETE.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/addresses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deleteAddress
     */
    async deleteAddressUsingDELETE(requestParameters: DeleteAddressUsingDELETERequest): Promise<void> {
        await this.deleteAddressUsingDELETERaw(requestParameters);
    }

    /**
     * getAddress
     */
    async getAddressUsingGETRaw(requestParameters: GetAddressUsingGETRequest): Promise<runtime.ApiResponse<Address>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAddressUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/addresses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressFromJSON(jsonValue));
    }

    /**
     * getAddress
     */
    async getAddressUsingGET(requestParameters: GetAddressUsingGETRequest): Promise<Address> {
        const response = await this.getAddressUsingGETRaw(requestParameters);
        return await response.value();
    }

    /**
     * getAllAddresses
     */
    async getAllAddressesUsingGETRaw(): Promise<runtime.ApiResponse<Array<Address>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/addresses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AddressFromJSON));
    }

    /**
     * getAllAddresses
     */
    async getAllAddressesUsingGET(): Promise<Array<Address>> {
        const response = await this.getAllAddressesUsingGETRaw();
        return await response.value();
    }

    /**
     * updateAddress
     */
    async updateAddressUsingPUTRaw(requestParameters: UpdateAddressUsingPUTRequest): Promise<runtime.ApiResponse<Address>> {
        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling updateAddressUsingPUT.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/addresses`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddressToJSON(requestParameters.address),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressFromJSON(jsonValue));
    }

    /**
     * updateAddress
     */
    async updateAddressUsingPUT(requestParameters: UpdateAddressUsingPUTRequest): Promise<Address> {
        const response = await this.updateAddressUsingPUTRaw(requestParameters);
        return await response.value();
    }

}
