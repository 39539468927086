import styled from 'styled-components'

const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
`

const SubTitle = styled.strong`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};
`

const Description = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #000000;
`

const SmartphoneSendCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 26px 20px 31px 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 22px 20px 28px 20px;
  }

  & > *[class^='SmartphoneSendCard__Title'] {
    margin-bottom: 6px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-bottom: 2px;
    }
  }

  & > *[class^='SmartphoneSendCard__SubTitle'] {
    margin-bottom: 12px;
  }

  & > *[class^='SmartphoneSendCard__Description'] {
    margin-bottom: 31px;
    max-width: 320px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-bottom: 16px;
    }
  }

  & > *[data-gatsby-image-wrapper] {
    margin-bottom: 26px;
  }
`

export default Object.assign(SmartphoneSendCard, {
  Title,
  SubTitle,
  Description,
})
