import styled from 'styled-components'

const Title = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #000000;
`

const PaymentMadeCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 15px 10px 17px 10px;
  text-align: center;
  position: relative;
  z-index: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 10px;
  }
`

export default Object.assign(PaymentMadeCard, {
  Title,
})
