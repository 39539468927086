// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ecommerce API
 * ecommerce API documentation
 *
 * The version of the OpenAPI document: 0.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PasswordValidation,
    PasswordValidationFromJSON,
    PasswordValidationToJSON,
} from '../models';

export interface UpdateWebUserUsingGETRequest {
    password: string;
}

/**
 * 
 */
export class PasswordResourceApi extends runtime.BaseAPI {

    /**
     * updateWebUser
     */
    async updateWebUserUsingGETRaw(requestParameters: UpdateWebUserUsingGETRequest): Promise<runtime.ApiResponse<PasswordValidation>> {
        if (requestParameters.password === null || requestParameters.password === undefined) {
            throw new runtime.RequiredError('password','Required parameter requestParameters.password was null or undefined when calling updateWebUserUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.password !== undefined) {
            queryParameters['password'] = requestParameters.password;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/checkPassword`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PasswordValidationFromJSON(jsonValue));
    }

    /**
     * updateWebUser
     */
    async updateWebUserUsingGET(requestParameters: UpdateWebUserUsingGETRequest): Promise<PasswordValidation> {
        const response = await this.updateWebUserUsingGETRaw(requestParameters);
        return await response.value();
    }

}
