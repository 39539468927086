// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ecommerce API
 * ecommerce API documentation
 *
 * The version of the OpenAPI document: 0.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PriceDTO,
    PriceDTOFromJSON,
    PriceDTOToJSON,
} from '../models';

export interface GetPriceBySkuUsingGETRequest {
    sku: string;
}

export interface GetPriceBySkuUsingGET1Request {
    productType: string;
    sku: string;
}

/**
 * 
 */
export class CatalogProductApi extends runtime.BaseAPI {

    /**
     * Récupère un element du catalog par sku
     */
    async getPriceBySkuUsingGETRaw(requestParameters: GetPriceBySkuUsingGETRequest): Promise<runtime.ApiResponse<PriceDTO>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling getPriceBySkuUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/sku/parents/{sku}`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PriceDTOFromJSON(jsonValue));
    }

    /**
     * Récupère un element du catalog par sku
     */
    async getPriceBySkuUsingGET(requestParameters: GetPriceBySkuUsingGETRequest): Promise<PriceDTO> {
        const response = await this.getPriceBySkuUsingGETRaw(requestParameters);
        return await response.value();
    }

    /**
     * Récupère un element du catalog par sku
     */
    async getPriceBySkuUsingGET1Raw(requestParameters: GetPriceBySkuUsingGET1Request): Promise<runtime.ApiResponse<PriceDTO>> {
        if (requestParameters.productType === null || requestParameters.productType === undefined) {
            throw new runtime.RequiredError('productType','Required parameter requestParameters.productType was null or undefined when calling getPriceBySkuUsingGET1.');
        }

        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling getPriceBySkuUsingGET1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/{productType}/{sku}`.replace(`{${"productType"}}`, encodeURIComponent(String(requestParameters.productType))).replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PriceDTOFromJSON(jsonValue));
    }

    /**
     * Récupère un element du catalog par sku
     */
    async getPriceBySkuUsingGET1(requestParameters: GetPriceBySkuUsingGET1Request): Promise<PriceDTO> {
        const response = await this.getPriceBySkuUsingGET1Raw(requestParameters);
        return await response.value();
    }

}
