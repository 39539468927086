// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ecommerce API
 * ecommerce API documentation
 *
 * The version of the OpenAPI document: 0.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressToJSON,
    Customer,
    CustomerFromJSON,
    CustomerToJSON,
} from '../models';

export interface CreateCustomerAddressUsingPOSTRequest {
    customerId: number;
    address: Address;
}

export interface UpdateCustomerAddressUsingPUTRequest {
    customerId: number;
    address: Address;
}

/**
 * 
 */
export class AddressApi extends runtime.BaseAPI {

    /**
     * Création d\'une adresse
     */
    async createCustomerAddressUsingPOSTRaw(requestParameters: CreateCustomerAddressUsingPOSTRequest): Promise<runtime.ApiResponse<Address>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling createCustomerAddressUsingPOST.');
        }

        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling createCustomerAddressUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/customer/{customerId}/address`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddressToJSON(requestParameters.address),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressFromJSON(jsonValue));
    }

    /**
     * Création d\'une adresse
     */
    async createCustomerAddressUsingPOST(requestParameters: CreateCustomerAddressUsingPOSTRequest): Promise<Address> {
        const response = await this.createCustomerAddressUsingPOSTRaw(requestParameters);
        return await response.value();
    }

    /**
     * Edition d\'une adresse
     */
    async updateCustomerAddressUsingPUTRaw(requestParameters: UpdateCustomerAddressUsingPUTRequest): Promise<runtime.ApiResponse<Customer>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling updateCustomerAddressUsingPUT.');
        }

        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling updateCustomerAddressUsingPUT.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/customer/{customerId}/address/edit/`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddressToJSON(requestParameters.address),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerFromJSON(jsonValue));
    }

    /**
     * Edition d\'une adresse
     */
    async updateCustomerAddressUsingPUT(requestParameters: UpdateCustomerAddressUsingPUTRequest): Promise<Customer> {
        const response = await this.updateCustomerAddressUsingPUTRaw(requestParameters);
        return await response.value();
    }

}
