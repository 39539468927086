import styled from 'styled-components'

const SmartphoneSendContainer = styled.div`
  flex: 1;
  padding: 0 21px 21px 21px;

  & > *[class^='SmartphoneSendCard'] {
    max-width: 506px;
    margin: 0 auto;
  }

  & > *[class^='PaymentCard'] {
    max-width: 616px;
    margin: 0 auto;
  }
`

export default SmartphoneSendContainer
