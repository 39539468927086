// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ecommerce API
 * ecommerce API documentation
 *
 * The version of the OpenAPI document: 0.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OrderInformation,
    OrderInformationFromJSON,
    OrderInformationToJSON,
} from '../models';

export interface CreateOrderInformationUsingPOSTRequest {
    orderInformation: OrderInformation;
}

export interface DeleteOrderInformationUsingDELETERequest {
    id: number;
}

export interface GetOrderInformationUsingGETRequest {
    id: number;
}

export interface UpdateOrderInformationUsingPUTRequest {
    orderInformation: OrderInformation;
}

/**
 * 
 */
export class OrderInformationResourceApi extends runtime.BaseAPI {

    /**
     * createOrderInformation
     */
    async createOrderInformationUsingPOSTRaw(requestParameters: CreateOrderInformationUsingPOSTRequest): Promise<runtime.ApiResponse<OrderInformation>> {
        if (requestParameters.orderInformation === null || requestParameters.orderInformation === undefined) {
            throw new runtime.RequiredError('orderInformation','Required parameter requestParameters.orderInformation was null or undefined when calling createOrderInformationUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/order-informations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderInformationToJSON(requestParameters.orderInformation),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderInformationFromJSON(jsonValue));
    }

    /**
     * createOrderInformation
     */
    async createOrderInformationUsingPOST(requestParameters: CreateOrderInformationUsingPOSTRequest): Promise<OrderInformation> {
        const response = await this.createOrderInformationUsingPOSTRaw(requestParameters);
        return await response.value();
    }

    /**
     * deleteOrderInformation
     */
    async deleteOrderInformationUsingDELETERaw(requestParameters: DeleteOrderInformationUsingDELETERequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOrderInformationUsingDELETE.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/order-informations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deleteOrderInformation
     */
    async deleteOrderInformationUsingDELETE(requestParameters: DeleteOrderInformationUsingDELETERequest): Promise<void> {
        await this.deleteOrderInformationUsingDELETERaw(requestParameters);
    }

    /**
     * getAllOrderInformations
     */
    async getAllOrderInformationsUsingGETRaw(): Promise<runtime.ApiResponse<Array<OrderInformation>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/order-informations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderInformationFromJSON));
    }

    /**
     * getAllOrderInformations
     */
    async getAllOrderInformationsUsingGET(): Promise<Array<OrderInformation>> {
        const response = await this.getAllOrderInformationsUsingGETRaw();
        return await response.value();
    }

    /**
     * getOrderInformation
     */
    async getOrderInformationUsingGETRaw(requestParameters: GetOrderInformationUsingGETRequest): Promise<runtime.ApiResponse<OrderInformation>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOrderInformationUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/order-informations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderInformationFromJSON(jsonValue));
    }

    /**
     * getOrderInformation
     */
    async getOrderInformationUsingGET(requestParameters: GetOrderInformationUsingGETRequest): Promise<OrderInformation> {
        const response = await this.getOrderInformationUsingGETRaw(requestParameters);
        return await response.value();
    }

    /**
     * updateOrderInformation
     */
    async updateOrderInformationUsingPUTRaw(requestParameters: UpdateOrderInformationUsingPUTRequest): Promise<runtime.ApiResponse<OrderInformation>> {
        if (requestParameters.orderInformation === null || requestParameters.orderInformation === undefined) {
            throw new runtime.RequiredError('orderInformation','Required parameter requestParameters.orderInformation was null or undefined when calling updateOrderInformationUsingPUT.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/order-informations`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrderInformationToJSON(requestParameters.orderInformation),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderInformationFromJSON(jsonValue));
    }

    /**
     * updateOrderInformation
     */
    async updateOrderInformationUsingPUT(requestParameters: UpdateOrderInformationUsingPUTRequest): Promise<OrderInformation> {
        const response = await this.updateOrderInformationUsingPUTRaw(requestParameters);
        return await response.value();
    }

}
