// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ecommerce API
 * ecommerce API documentation
 *
 * The version of the OpenAPI document: 0.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Payment,
    PaymentFromJSON,
    PaymentToJSON,
} from '../models';

export interface CreatePaymentUsingPOSTRequest {
    payment: Payment;
}

export interface DeletePaymentUsingDELETERequest {
    id: number;
}

export interface GetPaymentByReferenceUsingGETRequest {
    reference: string;
}

export interface GetPaymentUsingGETRequest {
    id: number;
}

export interface UpdatePaymentUsingPUTRequest {
    payment: Payment;
}

/**
 * 
 */
export class PaymentResourceApi extends runtime.BaseAPI {

    /**
     * createPayment
     */
    async createPaymentUsingPOSTRaw(requestParameters: CreatePaymentUsingPOSTRequest): Promise<runtime.ApiResponse<Payment>> {
        if (requestParameters.payment === null || requestParameters.payment === undefined) {
            throw new runtime.RequiredError('payment','Required parameter requestParameters.payment was null or undefined when calling createPaymentUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/payments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentToJSON(requestParameters.payment),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentFromJSON(jsonValue));
    }

    /**
     * createPayment
     */
    async createPaymentUsingPOST(requestParameters: CreatePaymentUsingPOSTRequest): Promise<Payment> {
        const response = await this.createPaymentUsingPOSTRaw(requestParameters);
        return await response.value();
    }

    /**
     * deletePayment
     */
    async deletePaymentUsingDELETERaw(requestParameters: DeletePaymentUsingDELETERequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePaymentUsingDELETE.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/payments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deletePayment
     */
    async deletePaymentUsingDELETE(requestParameters: DeletePaymentUsingDELETERequest): Promise<void> {
        await this.deletePaymentUsingDELETERaw(requestParameters);
    }

    /**
     * getAllPayments
     */
    async getAllPaymentsUsingGETRaw(): Promise<runtime.ApiResponse<Array<Payment>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/payments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PaymentFromJSON));
    }

    /**
     * getAllPayments
     */
    async getAllPaymentsUsingGET(): Promise<Array<Payment>> {
        const response = await this.getAllPaymentsUsingGETRaw();
        return await response.value();
    }

    /**
     * getPaymentByReference
     */
    async getPaymentByReferenceUsingGETRaw(requestParameters: GetPaymentByReferenceUsingGETRequest): Promise<runtime.ApiResponse<Payment>> {
        if (requestParameters.reference === null || requestParameters.reference === undefined) {
            throw new runtime.RequiredError('reference','Required parameter requestParameters.reference was null or undefined when calling getPaymentByReferenceUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/paymentsByReference/{reference}`.replace(`{${"reference"}}`, encodeURIComponent(String(requestParameters.reference))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentFromJSON(jsonValue));
    }

    /**
     * getPaymentByReference
     */
    async getPaymentByReferenceUsingGET(requestParameters: GetPaymentByReferenceUsingGETRequest): Promise<Payment> {
        const response = await this.getPaymentByReferenceUsingGETRaw(requestParameters);
        return await response.value();
    }

    /**
     * getPayment
     */
    async getPaymentUsingGETRaw(requestParameters: GetPaymentUsingGETRequest): Promise<runtime.ApiResponse<Payment>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPaymentUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/payments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentFromJSON(jsonValue));
    }

    /**
     * getPayment
     */
    async getPaymentUsingGET(requestParameters: GetPaymentUsingGETRequest): Promise<Payment> {
        const response = await this.getPaymentUsingGETRaw(requestParameters);
        return await response.value();
    }

    /**
     * updatePayment
     */
    async updatePaymentUsingPUTRaw(requestParameters: UpdatePaymentUsingPUTRequest): Promise<runtime.ApiResponse<Payment>> {
        if (requestParameters.payment === null || requestParameters.payment === undefined) {
            throw new runtime.RequiredError('payment','Required parameter requestParameters.payment was null or undefined when calling updatePaymentUsingPUT.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/payments`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentToJSON(requestParameters.payment),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentFromJSON(jsonValue));
    }

    /**
     * updatePayment
     */
    async updatePaymentUsingPUT(requestParameters: UpdatePaymentUsingPUTRequest): Promise<Payment> {
        const response = await this.updatePaymentUsingPUTRaw(requestParameters);
        return await response.value();
    }

}
