import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import RepairSummary from './RepairSummary'
import fixRepairLabel from '../utils/fixRepairLabel'

const PopupContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
`

const PopupBody = styled.div`
  position: absolute;
  max-width: 550px;
  max-height: 320px;
  left: 5%;
  right: 5%;
  bottom: 5%;
  top: 5%;
  text-align: center;
  margin: auto;
  border-radius: 5px;
  border-color: black;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    max-width: 550px;
    max-height: 250px;
  }
`

const Image = styled.span`
  display: inline-block;
  width: 100%;
  height: 100%;

  max-width: 193px;
  max-height: 193px;

  & > div {
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 365px;
    max-height: 365px;
  }
`

const Message = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: ${({ theme }) => theme.colors.body};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 15% 3%;
  }

  b {
    font-weight: bold;
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  right: 0;
  bottom: 0;
  padding: 5%;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 14px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
  }

  & > * {
    width: 133px;
    white-space: nowrap;
  }
`

interface MissingReparationProp {
  reparationMissing: string[]
  closePopup: () => void
}

const MissingReparationPopup: React.FC<MissingReparationProp> = ({
  reparationMissing,
  closePopup,
}) => {
  return (
    <PopupContainer>
      <PopupBody>
        <Image>
          <StaticImage
            id="image-oups"
            src="../images/Oups.png"
            alt="Oups"
            width={140}
            height={140}
            quality={100}
            placeholder="blurred"
          />
        </Image>
        <Message>
          Il n'est pas possible d'éditer un bon de réparation pour&nbsp;
          <b>
            {reparationMissing
              .map((reparation) => fixRepairLabel(reparation))
              .join(' et ')}
          </b>
          . Rendez-vous directement en magasin pour obtenir un devis.
          <Buttons>
            <RepairSummary.Button onClick={closePopup}>
              Poursuivre
            </RepairSummary.Button>
          </Buttons>
        </Message>
      </PopupBody>
    </PopupContainer>
  )
}
export default MissingReparationPopup
