// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * ecommerce API
 * ecommerce API documentation
 *
 * The version of the OpenAPI document: 0.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Attribute,
    AttributeFromJSON,
    AttributeToJSON,
    PriceDTO,
    PriceDTOFromJSON,
    PriceDTOToJSON,
} from '../models';

/**
 * 
 */
export class TestApi extends runtime.BaseAPI {

    /**
     * Feign test api
     */
    async catalogPingUsingGETRaw(): Promise<runtime.ApiResponse<PriceDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/test`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PriceDTOFromJSON(jsonValue));
    }

    /**
     * Feign test api
     */
    async catalogPingUsingGET(): Promise<PriceDTO> {
        const response = await this.catalogPingUsingGETRaw();
        return await response.value();
    }

    /**
     * Feign test api 2
     */
    async test2UsingGETRaw(): Promise<runtime.ApiResponse<Array<Attribute>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/test2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttributeFromJSON));
    }

    /**
     * Feign test api 2
     */
    async test2UsingGET(): Promise<Array<Attribute>> {
        const response = await this.test2UsingGETRaw();
        return await response.value();
    }

    /**
     * Feign test api 3
     */
    async test3UsingGETRaw(): Promise<runtime.ApiResponse<Array<PriceDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/test3`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PriceDTOFromJSON));
    }

    /**
     * Feign test api 3
     */
    async test3UsingGET(): Promise<Array<PriceDTO>> {
        const response = await this.test3UsingGETRaw();
        return await response.value();
    }

}
