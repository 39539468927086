import React, { useMemo } from 'react'
import { layoutProps as identificationLayoutProps } from '../pages/identification'
import { OnAuthenticated } from '../templates/RepairSummaryPage'
import AuthContainer from './AuthContainer'
import AuthForm from './AuthForm'
import { useLayoutContext } from './Layout'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import { LayoutProps } from './Layout.context'
import AuthWithoutAccountForm from './AuthWithoutAccountForm'

const summaryAuthLayoutProps: LayoutProps = {
  repairStepsContainerProps: {
    show: false,
  },
}

const layoutProps: LayoutProps = merge(
  cloneDeep(identificationLayoutProps),
  summaryAuthLayoutProps
)

interface RepairSummaryAuthProps {
  type: 'quote' | 'send'
  onBack: () => void
  onAuthenticated: OnAuthenticated
}

const RepairSummaryAuth: React.FC<RepairSummaryAuthProps> = ({
  // type,
  onAuthenticated,
  onBack,
}) => {
  const layoutPropsComputed: LayoutProps = useMemo(() => {
    const props: LayoutProps = {
      breadcrumb: [
        { label: 'Retour au récap', href: onBack },
        ...(layoutProps.breadcrumb ?? []),
      ],
    }

    return merge(cloneDeep(layoutProps), props)
  }, [layoutProps])

  useLayoutContext(layoutPropsComputed)

  return (
    <AuthContainer>
      <AuthForm.Card>
        <AuthWithoutAccountForm onAuthenticated={onAuthenticated} />
      </AuthForm.Card>
      {/* <AuthForm
        withoutAccount={type === 'quote'}
        onAuthenticated={onAuthenticated}
      /> */}
    </AuthContainer>
  )
}

export default RepairSummaryAuth
