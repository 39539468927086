import React from 'react'
import Banner from './Banner'
import Button from './Button'
import { useLayoutContext } from './Layout'
import { LayoutProps } from './Layout.context'
import PaymentMadeCard from './PaymentMadeCard'
import PaymentMadeContainer from './PaymentMadeContainer'

const layoutProps: LayoutProps = {
  title: 'Paiement effectué',
  repairStepsContainerProps: {
    show: false,
  },
}

const RepairSummaryPayment: React.FC = () => {
  useLayoutContext(layoutProps)

  return (
    <PaymentMadeContainer>
      <Banner
        type="validation"
        title="Paiement effectué avec succès !"
        description={
          <>
            Vous allez recevoir votre bon de transport part mail pour envoyer
            votre téléphone en magasin.
          </>
        }
      />

      <PaymentMadeCard>
        <PaymentMadeCard.Title>
          Suivez l’avancement de votre intervention sur votre{' '}
        </PaymentMadeCard.Title>
        <Button.Link to="#">Espace Client</Button.Link>
      </PaymentMadeCard>
    </PaymentMadeContainer>
  )
}

export default RepairSummaryPayment
